<template>
  <v-main>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <ExportLogBook :data="data" />
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>
<script>
import ExportLogBook from "./ExportExcel/ExportLogBook.vue";
export default {
  components: {
    ExportLogBook,
  },
  data() {
    return {
      data: [],
    };
  },
  async created() {
    //   https://logbookservice.yuzudigital.com/logs/getReport
    const response = await this.axios.get(
      `${process.env.VUE_APP_API}/logs/getReport`
    );
    console.log("response", response.data.data);
    this.data = response.data.data;
  },
};
</script>