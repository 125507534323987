<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-row justify="center" align="center">
              <v-img src="@/assets/logo.png" max-width="300px" />
              <span style="font-weight: 800; font-size: 36px" class="mt-2">
                Admin Logbook
            
                Web Portal
              </span>
            </v-row>
            <v-card class="elevation-12 mt-12">
              <v-toolbar color="#29B7E5" style="color: white">
                <p class="font-weight-light mt-4" style="font-size: 20px">
                  เข้าสู่ระบบ
                </p>
              </v-toolbar>
              <v-card-text>
                <v-form
                  ref="loginfrom"
                  v-model="loginfrom"
                  :lazy-validation="lazy"
                >
                  <v-text-field
                    placeholder="login"
                    type="text"
                    outlined
                    v-model="username"
                    :rules="Rules.empty"
                    @keyup.enter="login()"
                  ></v-text-field>
                  <v-text-field
                    placeholder="password"
                    type="password"
                    outlined
                    v-model="password"
                    :rules="Rules.empty"
                    @keyup.enter="login()"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="#29B7E5"
                  style="color: white"
                  class="font-weight-light"
                  @click="login()"
                  >Login</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { Encode, Decode } from "@/services";
export default {
  data() {
    return {
      lazy: "",
      loginfrom: "",
      Rules: {
        empty: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      username: "",
      password: "",
    };
  },
  created() {
    if (Object.prototype.hasOwnProperty.call(localStorage, "userCobens")) {
      var checklogin = JSON.parse(
        Decode.decode(localStorage.getItem("userCobens"))
      ).name;
      if (checklogin !== "") {
        // console.log(checklogin);
        // this.$router.push("/report");
        this.$router.push("/Dashboard");
      }
    }
  },
  methods: {
    resetValidation() {
      this.$refs.loginfrom.resetValidation();
    },
    async login() {
      const login = {
        username: this.username,
        password: this.password,
      };

      if (this.username == "admin" && this.password == "123456") {
        localStorage.setItem("userLogBook", Encode.encode(login));
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
          icon: "success",
          text: "เข้าสู่ระบบเสร็จสิ้น",
        });
        await this.$router.push("/Export");
        // }
        // const response = await this.axios.post(
        //   `${process.env.VUE_APP_API}/users/adminLogin`,
        //   login
        // );
        // console.log("login", response.data.data);

        // if (response.data.response_status === "SUCCESS") {
        //   await this.$swal.fire({
        //     showConfirmButton: false,
        //     timer: 1000,
        //     timerProgressBar: true,
        //     icon: "success",
        //     text: "เข้าสู่ระบบเสร็จสิ้น",
        //   });

        //   localStorage.setItem("userCobens", Encode.encode(response.data.data));

        //   await this.$router.push("/Dashboard");
      } else if (this.username == "" || this.password == "") {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
          icon: "error",
          text: "กรุณากรอก username password",
        });
      } else {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
          icon: "error",
          // text: response.data.message,
          text: "username หรือ password ไม่ถูกต้อง",
        });
      }
    },
  },
};
</script>

