import Vue from 'vue'
import VueRouter from 'vue-router'
import Homepage from '@/views/Homepage.vue'
import login from '../views/Login.vue'
import forgotPassword from '@/views/forgotPassword.vue'
import forgotPasswordSuccess from '@/views/forgotPasswordSuccess.vue'


Vue.use(VueRouter)

const routes = [{

    path: '/',
    name: 'login',
    component: login
},
{ path: '/forgotpassword', name: 'forgot', component: forgotPassword },
{ path: '/forgotPasswordSuccess', name: 'forgotSuccess', component: forgotPasswordSuccess },

{
    path: '/Home',
    component: Homepage,
    children: [
       
        {
            path: '/Export',
            name: 'Export',
            component: () =>
                import('@/views/Export')
        },
        // {
        //     path: '/ManageOrder',
        //     name: 'ManageOrder',
        //     component: () =>
        //         import('@/views/ManageOrder')
        // },
      
    ]
}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router