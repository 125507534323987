<template>
  <div id="app">
    <v-app id="inspire">
      <v-navigation-drawer v-model="drawer" app>
        <v-list height="180" class="mt-3">
          <a-row type="flex" justify="center" align="middle">
            <a-col :span="24" class="centerdiv">
              <!-- <v-avatar size="120">
                <img src="../assets/logo_color.png" />
              </v-avatar> -->
              <v-img
                src="@/assets/logo.png"
                max-height="150px"
                max-width="130px"
              ></v-img>
            </a-col>
            <a-col :span="24" class="centerdiv">
              <p style="font-weight: 400; font-size: 18px">{{ username }}</p>
            </a-col>
          </a-row>
        </v-list>
        <v-divider></v-divider>
        <!-- <a-menu mode="vertical">
          <a-menu-item
            v-for="item in Navigator"
            :key="item.key"
            @click="ChangePage(item)"
          >
            <a-icon :type="item.icon" />
            <span class="pl-5">{{ item.text }}</span>
          </a-menu-item>
        </a-menu> -->
        <a-menu mode="inline" @click="handleClick">
          <a-sub-menu
            @titleClick="titleClick"
            v-for="item in Navigator"
            :key="item.key"
          >
            <span slot="title">
              <span>{{ item.headerName }}</span>
            </span>
            <a-menu-item
              v-for="menuList in item.menuItem"
              :key="menuList.key"
              @click="ChangePage(menuList)"
            >
              <a-icon :type="menuList.icon" />
              {{ menuList.text }}
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </v-navigation-drawer>
      <v-app-bar app color="#29B7E5">
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          style="color: white"
        ></v-app-bar-nav-icon>
        <h3>{{ Header }}</h3>
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="Logout()"
          >ออกจากระบบ<a-icon type="import" class="ml-2"
        /></v-btn>
      </v-app-bar>
      <v-main class="div_overflow">
        <!-- <v-container grid-list-xs> -->
        <div class="pa-4">
          <router-view></router-view>
        </div>
        <!-- </v-container> -->
      </v-main>
    </v-app>
  </div>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      drawer: true,
      Header: "",
      // picture: "",
      username: "",
      NavigatorAdmin: [
        {
          headerName: "จัดการข้อมูล",
          menuItem: [
            {
              key: "menu0",
              text: "รูปสไลด์",
              path: "/ManageBanners",
              name: "ManageBanners",
              icon: "file-image",
            },
            {
              key: "menu1",
              text: "รูปผู้บังคับบัญชา",
              path: "/ManageBanners_1",
              name: "ManageBanners_1",
              icon: "user",
            },
            {
              key: "menu2",
              text: "ข่าวเด่น",
              path: "/ManageBanners_2",
              name: "ManageBanners_2",
              icon: "notification",
            },
            {
              key: "menu3",
              text: "ข่าวประชาสัมพันธ์",
              path: "/ManageBanners_3",
              name: "ManageBanners_3",
              icon: "notification",
            },
            {
              key: "menu4",
              text: "ข่าวสารและกิจกรรม",
              path: "/ManageBanners_4",
              name: "ManageBanners_4",
              icon: "notification",
            },
            {
              key: "menu5",
              text: "ข่าวประกาศ",
              path: "/ManageBanners_5",
              name: "ManageBanners_5",
              icon: "notification",
            },

            {
              key: "menu8",
              text: "หมายจับ",
              path: "/ManageBanners_8",
              name: "ManageBanners_8",
              icon: "lock",
            },
            {
              key: "menu9",
              text: "ศูนย์การเรียนรู้",
              path: "/ManageBanners_9",
              name: "ManageBanners_9",
              icon: "bulb",
            },
            {
              key: "menu10",
              text: "วีดีโอ",
              path: "/ManageBanners_10",
              name: "ManageBanners_10",
              icon: "youtube",
            },
          ],
        },
        {
          headerName: "ข้อมูลรีพอร์ท",
          menuItem: [
            {
              key: "table1",
              text: "ข้อมูลการแจ้งเบาะแส",
              path: "/table1",
              name: "table1",
              icon: "youtube",
            },
            {
              key: "table2",
              text: "รายชื่อผู้ต้องสงสัย",
              path: "/table2",
              name: "table2",
              icon: "youtube",
            },
            {
              key: "table3",
              text: "ข้อมูลตามประเภทข้อมูล",
              path: "/table3",
              name: "table3",
              icon: "youtube",
            },
            {
              key: "table4",
              text: "ข้อมูลตามประเภทยาเสพติด",
              path: "/table4",
              name: "table4",
              icon: "youtube",
            },
          ],
        },
        {
          headerName: "ผู้ใช้งาน",
          menuItem: [
            {
              key: "ManageUser",
              text: "ข้อมูลผู้ใช้งาน",
              path: "/ManageUser",
              name: "ManageUser",
              icon: "user",
            },
          ],
        },
      ],
      NavigatorDefaut: [
        {
          headerName: "ข้อมูลรีพอร์ท",
          menuItem: [
            {
              key: "table1",
              text: "ข้อมูลการแจ้งเบาะแส",
              path: "/table1",
              name: "table1",
              icon: "youtube",
            },
            {
              key: "table2",
              text: "รายชื่อผู้ต้องสงสัย",
              path: "/table2",
              name: "table2",
              icon: "youtube",
            },
            {
              key: "table3",
              text: "ข้อมูลตามประเภทข้อมูล",
              path: "/table3",
              name: "table3",
              icon: "youtube",
            },
            {
              key: "table4",
              text: "ข้อมูลตามประเภทยาเสพติด",
              path: "/table4",
              name: "table4",
              icon: "youtube",
            },
          ],
        },
      ],

      Navigator: [],
    };
  },
  created() {
    // this.Navigator = this.NavigatorAdmin;
    var checkuser = JSON.parse(
      Decode.decode(localStorage.getItem("userLogBook"))
    );
    console.log("user", checkuser);
    this.username = checkuser.name;

    if (checkuser.user.user_type == "99") {
      this.Navigator = this.NavigatorAdmin;
    } else {
      this.Navigator = this.NavigatorDefaut;
    }

    this.CheckHeader();
  },
  methods: {
    openKeys(val) {
      console.log("openKeys", val);
    },
    handleClick(e) {
      console.log("click", e);
    },
    titleClick(e) {
      console.log("titleClick", e);
    },
    async ChangePage(val) {
      // // console.log('เข้า ChangePage', val)
      // // console.log(val, this.$router.currentRoute)
      if (val.name !== this.$router.currentRoute.name) {
        this.$router.push({ path: val.path }).catch(() => {});
      }
    },
    CheckHeader(val) {
      this.Header = val;
    },
    Logout() {
      localStorage.removeItem("userLogBook");
      window.location.reload();
    },
  },
};
</script>
<style scoped>
.centerdiv {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.div_overflow {
  /* background-color: rgb(235, 240, 248); */
  overflow: auto;
  width: 100%;
  height: 94vh;
}
</style>
<style>
.v-application ul,
.v-application ol {
  padding: 0px 0px !important;
}
</style>
