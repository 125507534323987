<template>
  <v-row align="center" justify="center" class="text-center">
    <v-btn class="ml-4 mb-4"
      ><download-excel
        class="btn btn-default"
        :data="data"
        :fields="json_fields"
        worksheet="My Worksheet"
        name="LogBookทั้งหมด.xls"
        :header="'รายการLogBookทั้งหมด'"
      >
        <img src="@/assets/Excel-Logo.png" width="50px" />
        ดาวน์โหลดLogBookทั้งหมด
      </download-excel></v-btn
    >
  </v-row>
</template>
<script>
export default {
  props: ["data"],
  data() {
    return {
      json_fields: {
        คำนำหน้า: "prefix",
        นศ: "name",
        รหัส: "code",
        school: "school",
        system: "system",
        date: "date",
        patientName: "patientName",
        patientHN: "patientHN",
        diagnosis: "diagnosis",
        diagnosisOther: "diagnosisOther",
        twoDPlanning: "twoDPlanning",
        threeDPlanning: "threeDPlanning",
        imrt: "imrt",
        imageVerification: "imageVerification",
        imrtPlanning: "imrtPlanning",
        tbi: "tbi",
        imrtQa: "imrtQa",
        iort: "iort",
        stereotactic: "stereotactic",
        particleTherapy: "particleTherapy",
        icrt: "icrt",
        icrtISI: "icrtISI",
        isi: "isi",
        mould: "mould",
        emergency: "emergency",
        palliation: "palliation",
        residentMemo: "residentMemo",
        staffMemo: "staffMemo",

        // approveStatus: "1"
        // benignCondition: 0
        // breastCancer: 2
        // cnsTumor: 0
        // code: "51586"
        // createdAt: "2020-09-12T11:43:46.000Z"
        // date: "2020-09-14T07:32:05.000Z"
        // diagnosis: "Breast cancers"
        // diagnosisOther: ""
        // dupReason: null
        // email: null
        // emergency: 1
        // guCancer: 0
        // gynCancer: 7
        // gynMalignancies: 5
        // headAndNeckCancer: 1
        // hematologicMalignancies: 0
        // icrt: 0
        // icrtISI: 0
        // id: 48
        // imageVerification: 0
        // implantation: 0
        // imrt: 0
        // imrtPlanning: 0
        // imrtQa: 0
        // iort: 0
        // isi: 0
        // lowerGiCancer: 0
        // lungCancer: 0
        // mould: 0
        // name: "วรอนงค์ วงศ์ดินดำ"
        // onesignalId: null
        // palliation: 1
        // particleTherapy: 0
        // password: "241291"
        // patientHN: "IX7458"
        // patientName: "อ"
        // pediatric: 0
        // position: "แพทย์ประจำบ้าน"

        // resetPasswordToken: null
        // residentMemo: "FIMRT\n"

        // softTissueSarcoma: 0
        // staffMemo: ""
        // startTrainingYear: "2561"
        // stereotactic: 0
        // studentId: 48
        // system: "Breast cancers"
        // tbi: 0
        // teacherId: 49
        // threeDPlanning: 6
        // twoDPlanning: 0
        // type: "STUDENT"
        // updatedAt: "2021-08-27T09:10:56.000Z"
        // upperGiCancer: 0
        // username: "woraanong"
      },

      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      list1: [],
    };
  },
  async created() {
    // this.getREportXray();
  },
  methods: {
    // async getREportXray() {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/form/xrayReport`
    //   );
    //   console.log("data", response.data.data[0]);
    //   this.list1 = response.data.data[0];
    // },
  },
};
</script>
